import React, { useState, useEffect } from "react";
import "./aboutus.css";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import ImageUploading from 'react-images-uploading';
import Image from "../../assests/icons8-image-48.png";
import { Tooltip, Button } from 'antd';
import Loader from "../../components/fullpageloader/fullPageLoader"
import { Dropdown,Modal } from 'react-bootstrap';




function Aboutus() {
  const [images, setImages] = React.useState([]);
  const [allabout, setallaboutjob] = useState([]);


  const [aboutid, setaboutid] = useState();

  const aboutidfunc = (val)=>{
    setaboutid(val)
    handleShow()
  }



  useEffect(()=>{
    fetch("https://stag-server.hirechoices.com/about/get")
    .then(res => res.json())
    .then((response) => {
      setallaboutjob(response.job)
      setaboutid(response.job[0].id)
      setheading_1(response.job[0].heading_1)
      setdescription_1(response.job[0].description_1)
      setheading_2(response.job[0].heading_2)
      setdescription_2(response.job[0].description_2)
      setheading_3(response.job[0].heading_3)
      setdescription_3(response.job[0].description_3)
      const temp = []
      temp.push({data_url:response.job[0].image_1},{data_url:response.job[0].image_2})
      setImages(temp);
    })
  },[])




  const maxNumber = 2;
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  const heading_1func = (val)=>{
    setheading_1(val)
  }
  const description_1func = (val)=>{
    setdescription_1(val)
  }
  const heading_2func = (val)=>{
    setheading_2(val)
  }
  const description_2func = (val)=>{
    setdescription_2(val)
  }
  const heading_3func = (val)=>{
    setheading_3(val)
  }
  const description_3func = (val)=>{
    setdescription_3(val)
  }


  const [heading_1,setheading_1]=useState("")
  const [description_1,setdescription_1]=useState("")
  const [heading_2,setheading_2]=useState("")
  const [description_2,setdescription_2]=useState("")
  const [heading_3,setheading_3]=useState("")
  const [description_3,setdescription_3]=useState("")
  const [loaders,setloaders]=useState(false)

  const submit=()=>{
    setloaders(true)
    const update = {heading_1:heading_1,description_1:description_1,heading_2:heading_2,description_2:description_2,heading_3:heading_3,description_3:description_3,image_1:images[0].data_url,image_2:images[1].data_url,id:aboutid}
    fetch("https://stag-server.hirechoices.com/about/update", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
    .then((res) => {
      window.location="/aboutus"
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );
    })
  }





  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    {!loaders ?
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update About Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>


            {allabout&&allabout.filter(val1 => val1.id == aboutid).map(val => (
              <>
                  
              <div className="d-flex flex-column job-update-input-parent">
                <label>
                  Heading 1
                </label>
                <input type="text" defaultValue={val.heading_1} onChange={(e)=>{heading_1func(e.target.value)}} className="form-control"/>
              </div>
              <div className="d-flex flex-column job-update-input-parent">
                <label>
                  Description 1
                </label>
                <textarea rows="10" defaultValue={val.description_1} onChange={(e)=>{description_1func(e.target.value)}} className="form-control"/>
              </div>
              <div className="d-flex flex-column job-update-input-parent">
                <label>
                  Heading 2
                </label>
                <input type="text" defaultValue={val.heading_2} onChange={(e)=>{heading_2func(e.target.value)}} className="form-control"/>
              </div>
              <div className="d-flex flex-column job-update-input-parent">
                <label>
                  Description 2
                </label>
                <textarea rows="10" defaultValue={val.description_2} onChange={(e)=>{description_2func(e.target.value)}} className="form-control"/>
              </div>
              <div className="d-flex flex-column job-update-input-parent">
                <label>
                  Heading 3
                </label>
                <input type="text" defaultValue={val.heading_3} onChange={(e)=>{heading_3func(e.target.value)}} className="form-control"/>
              </div>
              <div className="d-flex flex-column job-update-input-parent mb-3">
                <label>
                Description 3
                </label>
                <textarea rows="10" defaultValue={val.description_3} onChange={(e)=>{description_3func(e.target.value)}}  className="form-control"/>
              </div>
              <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button 
                        className="image-uploader"
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <span>
                          <img src={Image} alt="" />Upload Image
                        </span>
                      </button>
                      <div className="image-item row">
                        {imageList.map((image, index) => (
                            <div key={index} className="col-3 image-display-inner">
                              <img src={image['data_url']} alt="" width="100%" height="100" />
                              <div className="image-item__btn-wrapper">
                                <button className="update-image" onClick={() => onImageUpdate(index)}><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                <button className="delete-image" onClick={() => onImageRemove(index)}><i class="fa fa-trash" aria-hidden="true"></i></button>
                              </div>
                            </div>
                        ))}
                        </div>

                    </div>
                  )}
              </ImageUploading>
              </>
            ))}


            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={()=>{submit()}} >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Navbar />
          <Sidebar />
          <div className="dashboard-parent all-main responsive-tal">
          <div className="d-flex align-items-start w-100">
            <div className="w-100 bg-white p-4">
            <div className="recr-table w-100 d-flex align-items-center justify-content-between">
                <h3>About Us</h3>
              </div>
              <div className="user-list">
                <table style={{display:"inline-table"}} class="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Heading 1</th>
                    <th scope="col">Heading 2</th>
                    <th scope="col">Heading 3</th>
                    <th scope="col">Update</th>
                  </tr>
                </thead>
                <tbody>
    
    
                  {allabout&&allabout.length>0?allabout.map(val => (
                    <tr>
                    <td>{val.heading_1}</td>
                    <td>{val.heading_2}</td>
                    <td>{val.heading_3}</td>
                    <td className="user-action">
                        <Tooltip placement="topLeft" title={"Update"}>
                        <button onClick={()=>{aboutidfunc(val.id)}}>
                          <i class="fa fa-pencil mr-2 text-success" aria-hidden="true"></i>
                        </button>
                        </Tooltip>
                    </td>
                    </tr>
                  )):<p className="py-2">No About Us Available</p>}
                 
    
    
                </tbody>
              </table>
                  
              </div>
              </div>
            </div>
          </div>
        </>
        :<Loader/>}
        </>
  );
}


export default Aboutus;


// code from scoopsolutions