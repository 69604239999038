import React, { useState,useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "./invoice.css";
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import { Skeleton } from 'antd';
import Pdf from "./pdf";
import Icon, { CloudDownloadOutlined } from '@ant-design/icons';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Progress } from 'antd';


TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)



function Paidinvoice() {
  const [Loader,setLoader] = useState(false)

  useEffect(() => {
    setLoader(true)
    fetch('https://stag-server.hirechoices.com/getAllUser')
    .then(res => res.json())
    .then((response) => {
      setalluser(response.user)
    })
    .catch(()=>{setLoader(false)})
  }
  ,[]);

  

  useEffect(() => {
    setLoader(true)
    fetch('https://stag-server.hirechoices.com/All/PaymentRecieve')
    .then(res => res.json())
    .then((response) => {
      setalljobs(response.invoices)
    })
    .then(response=>{setLoader(false)})
    .catch(()=>{setLoader(false)})
  }
  ,[]);
  const [alljobs,setalljobs] = useState([])
  const [alluser,setalluser] = useState([])
  const [time,settime]=useState([])




  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
        <div className="d-flex align-items-start w-100">
        <div className="w-100 bg-white p-4">
          <div className="recr-table w-100 d-flex align-items-center justify-content-between">
            <h3>Paid Invoice</h3>
          </div>
          <div className="user-list">
          {Loader == false ?
            <>
            <table style={{display:"inline-table"}} class="table table-hover table-responsive">
            <thead>
              <tr>
                <th scope="col">Invoice no</th>
                <th scope="col">Package</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Company Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Created at</th>
                <th scope="col">End at</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            
            <tbody>
              {alljobs&&alljobs.length>0?alljobs.map((val,ind) => (
                <>
                  <tr>
                    <td>{val.invoice_no}</td>
                    <td>{val.pkg_id == 1?"Standard":""||val.pkg_id == 2?"Premimum":""||val.pkg_id == 3?"Enterprise":""}</td>
                    <td>{alluser&&alluser.length>0?alluser[ind].first_name:""}</td>
                    <td>{alluser&&alluser.length>0?alluser[ind].last_name:""}</td>
                    <td>{alluser&&alluser.length>0?alluser[ind].company_name:""}</td>
                    <td>${val.payment_recieve}</td>
                    <td><i class="fa fa-check text-success" aria-hidden="true"></i></td>
                    <td><ReactTimeAgo date={val.created_At} locale="en-US"/></td>
                    <td><ReactTimeAgo date={val.enddate} locale="en-US"/></td>
                      <td>
                        <PDFDownloadLink document={<Pdf id={val.id}/>} fileName="somename.pdf">
                          <CloudDownloadOutlined />
                        </PDFDownloadLink>
                      </td>
                  </tr>
                </>
              )):<p className="py-2">No Invoice Available</p>}
            </tbody>
          </table>
            </>
            :<Skeleton active />}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paidinvoice;


// code from scoopsolutions