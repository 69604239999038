import React, { useState, useEffect } from "react";
import "./Login.css";
import backImage from "../../assests/login-covers.jpg";
import logo from "../../assests/mob-logo.png";
import { SignIn } from "../../Actions/authActions";
import { connect } from "react-redux";
import { Form, Input, Button, Checkbox } from 'antd';
import Loader from "../fullpageloader/fullPageLoader"

function Login(props) {
  const [eror,seteror]=useState(false)

  const [loaders,setloaders]=useState(false)

  const onFinish = (values) => {
    setloaders(true)
    console.log('Success:', values);
    fetch("https://stag-server.hirechoices.com/adminlogin", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(values)
    })
    .then((response) => {return response.json()})
    .then((js) => {
      seteror(js);
      if(js.user.role == 4){
        let user = js.token;
        let first_name = js.user.first_name;
        localStorage.setItem("user", user);
        localStorage.setItem("first_name", first_name);
        window.location = "/dashboard";
      }
      else{
        window.location = "/";
      }
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );
    })
    .catch(function(err){
      setloaders(false)
      console.log(err)
    })
  };
  const onFinishFailed = (errorInfo) => {
    setloaders(false)
    console.log('Failed:', errorInfo);
  };




  return (
    <>
    {!loaders ?
    <div className="login-parent">
      <div className="login-child-login-form">
        <img src={logo} />
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            className="m-0"
            label="email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <p style={{marginLeft:"90px"}} className="text-danger">{eror.message}</p>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" className="login-btn" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
    :<Loader/>}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  SignIn: (username, password) => dispatch(SignIn(username, password)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);









