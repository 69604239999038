import React, { useState,useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "./invoice.css";
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import { Skeleton } from 'antd';



TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


function InvoiceD() {
  const [Loader,setLoader] = useState(false)
  useEffect(() => {
    setLoader(true)
    fetch('https://stag-server.hirechoices.com/All/DueInvoices')
    .then(res => res.json())
    .then((response) => {
      setalljobs(response.invoices)
    })
    .then(response=>{setLoader(false)})
    .catch(()=>{setLoader(false)})
  }
  ,[]);

  const [alljobs,setalljobs] = useState([])
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
        <div className="d-flex align-items-start w-100">
        <div className="w-100 bg-white p-4">
        <div className="recr-table w-100 d-flex align-items-center justify-content-between">
            <h3>Due Invoice</h3>
          </div>
          <div className="user-list">
          {Loader == false ?
            <>
            <table style={{display:"inline-table"}} class="table table-hover table-responsive">
            <thead>
              <tr>
                <th scope="col">Payment Recieve</th>
                <th scope="col">Invoice no</th>
                <th scope="col">Status</th>
                <th scope="col">Created at</th>
                <th scope="col">Updated at</th>
              </tr>
            </thead>
            
            <tbody>
              <>
              {alljobs&&alljobs.length>0?alljobs.map((val) => 
                <>
                <tr>
                  <td>{val.payment_recieve} $</td>
                  <td>{val.invoice_no}</td>
                  <td><span style={{backgroundColor:"red",paddingInline:10,paddingBlock:5,borderRadius:5,color:"white",cursor:"pointer"}}>{val.status == 0 ?"pending":"Payment Recived"}</span></td>
                  <td><ReactTimeAgo date={val.created_At} locale="en-US"/></td>
                  <td><ReactTimeAgo date={val.updated_At} locale="en-US"/></td>
                </tr>
                </>
              ):<p className="py-2">No Invoice Available</p>}
              </>
            </tbody>
          </table>
          </>
            :<Skeleton active />}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceD;


// code from scoopsolutions