import React, { useState,useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "./jobs.css";
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import { Skeleton } from 'antd';
import {useLocation} from "react-router-dom";
import { Progress } from 'antd';
import { Tooltip, Button } from 'antd';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


function Appliedjobs() {
  const [Loader,setLoader] = useState(false)
  const search = useLocation().search;
  const name = new URLSearchParams(search).get('id');

  useEffect(() => {
    setLoader(true)
    fetch(`https://stag-server.hirechoices.com/applyjobs/${name}`)
    .then(res => res.json())
    .then((response) => {
      setalljobs(response.job)
      setheading(response.job[0].job_applied)
    })
    .then(response=>{setLoader(false)})
    .catch(()=>{setLoader(false)})
  }
  ,[]);

  useEffect(() => {
    setLoader(true)
    fetch('https://stag-server.hirechoices.com/getAllUser')
    .then(res => res.json())
    .then((response) => {
      setalluser(response.user)
    })
    .catch(()=>{setLoader(false)})
  }
  ,[]);
  const [alluser,setalluser] = useState([])
  const [alljobs,setalljobs] = useState([])
  const [heading,setheading] = useState()
  console.log(alluser,"asdsadash")
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
        <div className="d-flex align-items-start w-100">
        <div className="w-100 bg-white p-4">
        <div className="recr-table w-100 d-flex align-items-center justify-content-between">
            <h3>{heading}</h3>
          </div>
          <div className="user-list">
          {Loader == false ?
            <>
            <table style={{display:"inline-table"}} class="table table-hover table-responsive">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact</th>
                <th scope="col">Date Applied</th>
                <th scope="col">Contacted</th>
                <th scope="col">Resume</th>
              </tr>
            </thead>
            
            <tbody>
              <>
              {alljobs&&alljobs.length>0?alljobs.map((val,ind) => {
                const Data_applied = val.date_applied.split(" ")
                const filteruse = Data_applied[0].split("-")
                return(
<>
                <tr>
                  <td>{val.candidate_name}</td>
                  <td>{alluser&&alluser.length>0?alluser.filter(buk => buk.id == val.candidate_id)[0].email:""}</td>
                  <td>{alluser&&alluser.length>0?alluser.filter(buk => buk.id == val.candidate_id)[0].phone:""}</td>
                  <td>{filteruse[0]+"-"+filteruse[1]}</td>
                  <td><span style={{backgroundColor:val.status == 0 ? "red":"green",paddingInline:10,paddingBlock:5,borderRadius:5,color:"white",cursor:"pointer"}}>{val.status == 0 ? "Uncontacted":"Contacted"}</span></td>
                  <td>
                    <Tooltip placement="topLeft" title={"View"}>
                    <a href={`/resumes/detail/?id=${val.candidate_id}`} style={{background:"transparent",border:"none"}} >
                      <i class="fa fa-eye mr-2 text-success" aria-hidden="true"></i>
                    </a>
                    </Tooltip>
                  </td>
                </tr>
                </>
                )
              }
                
              ):<p className="py-2">No Detail Available</p>}
              </>
            </tbody>
          </table>
          </>
            :<Skeleton active />}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appliedjobs;


// code from scoopsolutions