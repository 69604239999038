import React, { useState,useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "./jobs.css";
import TimeAgo from 'javascript-time-ago';
import { Dropdown,Modal } from 'react-bootstrap';
import {Link,NavLink} from "react-router-dom";
import ReactTimeAgo from 'react-time-ago';
import { Select } from 'antd';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import { Form, Input, Button } from 'antd';
import parse from 'html-react-parser';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import Loader from "../../components/fullpageloader/fullPageLoader"
// import Loader2 from "../../components/fullpageloader/fullPageLoader2"
import { Skeleton } from 'antd';





TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


function Jobs() {
  const [loaders,setloaders]=useState(false)
  const [smloaders,setsmloaders]=useState(false)

  useEffect(() => {
    setsmloaders(true)
    fetch('https://stag-server.hirechoices.com/getAlljobs')
    .then(res => res.json())
    .then((response) => {
      setalljobs(response.resume)
      setjobid(response.resume[0].id)
    })
    .then(response=>{
        setsmloaders(false)
    })
    .catch(()=>{
      setsmloaders(false)
    })
  }
  ,[]);
  const deletejob = (e) =>{
      setloaders(true)
      const update = {id:e}
      fetch("https://stag-server.hirechoices.com/jobs/delete", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then((res)=>{return res.json()})
    .then(response=>{
      window.location="/jobs"
      setTimeout(
        () => this.setState(setloaders(false)), 
        3000
      );
    })
  }

  const [jobfilterid,setjobfilterid] = useState([])


  const setjobid = (e) =>{
    fetch(`https://stag-server.hirechoices.com/jobs/getbyid/${e}`)
    .then(res => res.json())
    .then((response) => {
      setjobfilterid(response)
    })
  }
  const temtjob = (e) =>{
    
    setupdateid(e)
    setShow(true);
    fetch(`https://stag-server.hirechoices.com/jobs/getbyid/${e}`)
    .then(res => res.json())
    .then((response) => {
      setid(response.job[0].id)
      setfirst_name(response.job[0].first_name)
      setlast_name(response.job[0].last_name)
      setcompany_name(response.job[0].company_name)
      setcompany_size(response.job[0].company_size)
      setphone_number(response.job[0].phone_number)
      sethear_about_us(response.job[0].hear_about_us)
      setjob_title(response.job[0].job_title)
      setlocation(response.job[0].location)
      setcity(response.job[0].city)
      setlatitude(response.job[0].latitude)
      setlongitude(response.job[0].longitude)
      setstate(response.job[0].state)
      setzip_code(response.job[0].zip_code)
      setemployment(response.job[0].employment)
      setexperience_level(response.job[0].experience_level)
      seteducation(response.job[0].education)
      setpay_rate_minimum(response.job[0].pay_rate_minimum)
      setpay_rate_maximum(response.job[0].pay_rate_maximum)
      setpay_rate_type(response.job[0].pay_rate_type)
      setother_compensations(response.job[0].other_compensations)
      setdisplay_amount(response.job[0].display_amount)
      setdescription(response.job[0].description)
      setcompany_overview(response.job[0].company_overview)
      settravel(response.job[0].travel)
      setlinkedin(response.job[0].linkedin)
      setfacebook(response.job[0].facebook)
      settwitter(response.job[0].twitter)
      setblog(response.job[0].blog)
      setposition_opened(response.job[0].position_opened)
      setemail(response.job[0].email)
      setsalary(response.job[0].salary)
    })
  }
  const[id,setid]=useState()
  const[first_name,setfirst_name]=useState("")
  const[last_name,setlast_name]=useState("")
  const[company_name,setcompany_name]=useState("")
  const[company_size,setcompany_size]=useState("")
  const[phone_number,setphone_number]=useState("")
  const[hear_about_us,sethear_about_us]=useState("")
  const[job_title,setjob_title]=useState("")
  const[location,setlocation]=useState("")
  const[city,setcity]=useState("")
  const[state,setstate]=useState("")
  const[latitude,setlatitude]=useState("")
  const[longitude,setlongitude]=useState("")
  const[zip_code,setzip_code]=useState("")
  const[employment,setemployment]=useState("")
  const[experience_level,setexperience_level]=useState("")
  const[education,seteducation]=useState("")
  const[pay_rate_minimum,setpay_rate_minimum]=useState("")
  const[pay_rate_maximum,setpay_rate_maximum]=useState("")
  const[pay_rate_type,setpay_rate_type]=useState("")
  const[other_compensations,setother_compensations]=useState("")
  const[display_amount,setdisplay_amount]=useState("")
  const[description,setdescription]=useState("")
  const[company_overview,setcompany_overview]=useState("")
  const[travel,settravel]=useState("")
  const[linkedin,setlinkedin]=useState("")
  const[facebook,setfacebook]=useState("")
  const[twitter,settwitter]=useState("")
  const[blog,setblog]=useState("")
  const[position_opened,setposition_opened]=useState("")
  const[email,setemail]=useState("")
  const[salary,setsalary]=useState("")


  const [showdelete, setShowdelete] = useState(false);
  const [deletjobeid, setdeletjobeid] = useState();
  const handleClosedelete = () => setShowdelete(false);

  const handleShowdelete  = (val) => {
    setShowdelete(true)
    setdeletjobeid(val)
  }



  const [alljobs,setalljobs] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);


  

  const first_name1 = (values) => {
    setfirst_name(values)
  };
  const last_name1 = (values) => {
    setlast_name(values)
  };
  const company_name1 = (values) => {
    setcompany_name(values)
  };
  const company_size1 = (values) => {
    setcompany_size(values)
  };
  const phone_number1 = (values) => {
    setphone_number(values)
  }; 
  const hear_about_us1 = (values) => {
    sethear_about_us(values)
  };
  const job_title1 = (values) => {
    setjob_title(values)
  };
  const location1 = (values) => {
    setlocation(values)
  };
  const city1 = (values) => {
    setcity(values)
  };
  const state1 = (values) => {
    setstate(values)
  };
  const zip_code1 = (values) => {
    setzip_code(values)
  }; 
  const employment1 = (values) => {
    setemployment(values)
  };
  const experience_level1 = (values) => {
    setexperience_level(values)
  };
  const education1 = (values) => {
    seteducation(values)
  };
  const pay_rate_minimum1 = (values) => {
    setpay_rate_minimum(values)
  };
  const pay_rate_maximum1 = (values) => {
    setpay_rate_maximum(values)
  };
  const pay_rate_type1 = (values) => {
    setpay_rate_type(values)
  };
  const other_compensations1 = (values) => {
    setother_compensations(values)
  };
  const description1 = (values) => {
    setdescription(values)
  };
  const company_overview1 = (values) => {
    setcompany_overview(values)
  };
  const position_opened1 = (values) => {
    setposition_opened(values)
  };
  const travel1 = (values) => {
    settravel(values)
  };
  const linkedin1 = (values) => {
    setlinkedin(values)
  };
  const facebook1 = (values) => {
    setfacebook(values)
  };
  const twitter1 = (values) => {
    settwitter(values)
  };
  const blog1 = (values) => {
    setblog(values)
  };
  const email1 = (values) => {
    setemail(values)
  };
  const salary1 = (values) => {
    setsalary(values)
  };

  const [updateid,setupdateid] = useState()

  const submit=()=>{
    setloaders(true)
    const update = {first_name:first_name,last_name:last_name,company_name:company_name,company_size:company_size,phone_number:phone_number,hear_about_us:hear_about_us,job_title:job_title,location:location,city:city.split(",")[0],latitude:corr.lat!==null&&corr.lat!==undefined&&corr.lat!==""?corr.lat:latitude,longitude:corr.lng!==null&&corr.lng!==undefined&&corr.lng!==""?corr.lng:longitude,state:state,zip_code:zip_code,employment:employment,experience_level:experience_level,education:education,pay_rate_minimum:pay_rate_minimum,pay_rate_maximum:pay_rate_maximum,pay_rate_type:pay_rate_type,other_compensations:other_compensations,description:description,company_overview:company_overview,travel:travel,linkedin:linkedin,facebook:facebook,twitter:twitter ,blog:blog,position_opened:position_opened,email:email,salary:salary,id:id}
    fetch("https://stag-server.hirechoices.com/jobs/update", {
      method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(update)
    })
    .then(response => response.json())
        .then(res => window.location="/jobs")
        setTimeout(
          () => this.setState(setloaders(false)), 
          3000
        );
  }
  const[corr,setcorr]=useState({
    lat:null,
    lng:null
  })
  const handleSelect=async(value)=>{
  const result=await geocodeByAddress(value)
  const ll=await getLatLng(result[0])
  setcity(value)
  setcorr(ll)
  }
 

  const
  keys = ['state'],
  filtered = alljobs.filter(
      (s => o => 
          (k => !s.has(k) && s.add(k))
          (keys.map(k => o[k]).join('|'))
      )
      (new Set)
      
  );
  const [filterlock,setfilterlock]=useState(false)
  
  
  const selffilterstate=(val)=>{
    if(val!=1){
    setjobid(alljobs.filter(data=>data.state==val)[0].id)
    }
    else{
      setjobid(alljobs[0].id)
    }
    setfilterState(val)
    setfilterlock(true)
    if(val == 1){
    setfilterlock(false)
    }
  }
  const [filterState,setfilterState]=useState("")
  return (
    <>
    {!loaders ?
    <>
      <Modal show={showdelete} onHide={handleClosedelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete <i  class="fa fa-trash mr-2 text-danger" aria-hidden="true"></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure To Delete This Job</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            No, Don't
          </Button>
          <Button variant="primary" onClick={()=>deletejob(deletjobeid)}>
          Yes, Delete Job
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="jobupdatedropdown" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              First Name
            </label>
            <input type="text" className="form-control" defaultValue={first_name} onChange={(e)=>{first_name1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Last Name
            </label>
            <input type="text" className="form-control" defaultValue={last_name} onChange={(e)=>{last_name1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Company Name
            </label>
            <input type="text" className="form-control" defaultValue={company_name} onChange={(e)=>{company_name1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Company Size
            </label>
            <input type="text" className="form-control" defaultValue={company_size} onChange={(e)=>{company_size1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Phone Number
            </label>
            <input type="text" className="form-control" defaultValue={phone_number} onChange={(e)=>{phone_number1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Hear About Us
            </label>
            <input type="text" className="form-control" defaultValue={hear_about_us} onChange={(e)=>{hear_about_us1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Job Title
            </label>
            <input type="text" className="form-control" defaultValue={job_title} onChange={(e)=>{job_title1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Location
            </label>
            <input type="text" className="form-control" defaultValue={location} onChange={(e)=>{location1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              city
            </label>
            <PlacesAutocomplete
        value={city.split(",")[0]}
        // defaultValue={address!==""?address.split(",")[0]:value.job[0].city}
        onChange={setcity}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
            id="exampleInputPassword1"
            style={{height:33}}
            className="form-control employee-input-email job-search w-25"
              {...getInputProps({
                placeholder: 'city',
                className: 'form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
            {/* <input type="text" className="form-control" defaultValue={city} onChange={(e)=>{city1(e.target.value)}} /> */}
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              State
            </label>
            <input type="text" className="form-control" defaultValue={state} onChange={(e)=>{state1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Zip Code
            </label>
            <input type="text" className="form-control" defaultValue={zip_code} onChange={(e)=>{zip_code1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Employment
            </label>
            <input type="text" className="form-control" defaultValue={employment} onChange={(e)=>{employment1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Experience Level
            </label>
            <input type="text" className="form-control" defaultValue={experience_level} onChange={(e)=>{experience_level1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
              Education
            </label>
            <input type="text" className="form-control" defaultValue={education} onChange={(e)=>{education1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Pay Rate Minimum
            </label>
            <input type="text" className="form-control" defaultValue={pay_rate_minimum} onChange={(e)=>{pay_rate_minimum1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Pay Rate Maximum
            </label>
            <input type="text" className="form-control" defaultValue={pay_rate_maximum} onChange={(e)=>{pay_rate_maximum1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Pay Rate Type
            </label>
            <input type="text" className="form-control" defaultValue={pay_rate_type} onChange={(e)=>{pay_rate_type1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Other Compensations
            </label>
            <input type="text" className="form-control" defaultValue={other_compensations} onChange={(e)=>{other_compensations1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Description
            </label>
            <textarea type="text" rows="6" className="form-control" defaultValue={description} onChange={(e)=>{description1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Company Overview
            </label>
            <textarea type="text" rows="6" className="form-control" defaultValue={company_overview} onChange={(e)=>{company_overview1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Travel
            </label>
            <input type="text" className="form-control" defaultValue={travel} onChange={(e)=>{travel1(e.target.value)}} />
          </div>




          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Linkedin
            </label>
            <input type="text" className="form-control" defaultValue={linkedin} onChange={(e)=>{linkedin1(e.target.value)}} />
          </div>


          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Facebook
            </label>
            <input type="text" className="form-control" defaultValue={facebook} onChange={(e)=>{facebook1(e.target.value)}} />
          </div>



          <div className="d-flex flex-column job-update-input-parent">
            <label>
            twitter
            </label>
            <input type="text" className="form-control" defaultValue={twitter} onChange={(e)=>{twitter1(e.target.value)}} />
          </div>



          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Blog
            </label>
            <input type="text" className="form-control" defaultValue={blog} onChange={(e)=>{blog1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Position Opened
            </label>
            <input type="text" className="form-control" defaultValue={position_opened} onChange={(e)=>{position_opened1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Email
            </label>
            <input type="text" className="form-control" defaultValue={email} onChange={(e)=>{email1(e.target.value)}} />
          </div>
          <div className="d-flex flex-column job-update-input-parent">
            <label>
            Salary
            </label>
            <input type="text" className="form-control" defaultValue={salary} onChange={(e)=>{salary1(e.target.value)}} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{submit()}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    <Navbar/>
    <Sidebar/>
      
      <div className="dashboard-parent all-main responsive-tal">
        <div className="job-filtration-in_dash mt-0 d-flex">
              <select onChange={(e)=>{
                selffilterstate(e.target.value)}} placeholder="Search by State">
              <option value={1}>Filter by State</option>
                {filtered&&filtered.length>0?filtered.map(val =>(
                  <>
                  <option value={val.state}>{val.state}</option>
                  </>
                  )):""}
                </select>
        </div>
        <div className="d-flex align-items-start mt-4">
          <div className="all-main-jobs-left">
          {smloaders?
            <>
            <Skeleton active/>
            <Skeleton active/>
            </>
            :
            <>
            {alljobs&&alljobs.length>0?alljobs.filter((tar) => {
              if(filterlock == true){
              return(tar.state == filterState)
              }
              else{
                return(tar.state)
              }
            }).map(data =>
            <>
            <div onClick={()=>{setjobid(data.id)}} className="all-main-jobs-left-all-jobs">
                <div className="comapny-icon">
                  <h1 className="m-0">HC</h1>
                </div>
                <div className="d-flex flex-column justify-content-start h-100 py-1">
                  <h5 className="mb-0">{data.job_title }</h5>
                  <p style={{borderBottom:"1px solid black",width:"fit-content"}}>{data.company_name}</p>
                  <p className="mt-0">{data.state}</p>
                  <p className="mt-2"><ReactTimeAgo date={data.created_at} locale="en-US"/></p>
                </div>
            </div>
            <hr/>
            </>
            ):
              <div className="w-100 bg-white text-center p-4">
                <h3 className="m-0">No Jobs Are Available</h3>
              </div>
            }
            </>
          }
          </div>
          {smloaders?
            <div className="all-main-jobs-right p-4 ">
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>

            </div>
            :
            <>
            {jobfilterid.job&&jobfilterid.job.length>0?jobfilterid.job.map((data) =>
            <div className="all-main-jobs-right p-4 ">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1">{data.job_title}</h4> 
                    <a href={`/applied/jobs/?id=${data.id}`} style={{background:"transparent",border:"none",color:"blue",cursor:"pointer"}} className="ml-2">( View applicant )</a>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle className="drop-down-btn" id="dropdown-basic">
                    <i class="fa fa-ellipsis-v text-dark" aria-hidden="true"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={()=>{temtjob(data.id)}}>
                      <i class="fa fa-pencil mr-2 text-success" aria-hidden="true"></i> Update
                      </Dropdown.Item>
                      <Dropdown.Item onClick={()=>handleShowdelete(data.id)}>
                        <i  class="fa fa-trash mr-2 text-danger" aria-hidden="true"></i>Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex align-items-center">
                  <p>{data.company_name}</p> <span><h4 className="m-0">/</h4></span> <p>{data.city}</p> <span><h4 className="m-0">/</h4></span> <p><ReactTimeAgo date={data.created_at} locale="en-US"/></p>
                </div>

                {
  data.hear_about_us!==""&&data.hear_about_us!==null&&data.hear_about_us!==undefined?
  <div>
  <p><b>Hear about us:</b>{data.hear_about_us}</p>
  </div>:
  ""
}

                <div className="d-flex align-items-center mt-4">
                  <i class="fa fa-briefcase" aria-hidden="true"></i>
                  <p className="ml-1">{data.employment} / {data.experience_level}</p>
                </div>


                {data.company_size!==undefined&&data.company_size!==null&&data.company_size!==""?
                <div className="d-flex align-items-center mt-2">
                <i class="fa fa-building" aria-hidden="true"></i>
                  <p className="ml-1">{data.company_size} Employees</p>
                </div>
                :""}

{data.education!==undefined&&data.education!==null&&data.education!==""?
                <div className="d-flex align-items-center mt-2">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                  <p className="ml-1">{data.education}</p>
                </div>
                :""}


{data.phone_number!==undefined&&data.phone_number!==null&&data.phone_number!==""?

                <div className="d-flex align-items-center mt-2">
                  <i class="fa fa-mobile" aria-hidden="true"></i>
                  <p className="ml-1">{data.phone_number}</p>
                </div>
                :""}


{data.email!==undefined&&data.email!==null&&data.email!==""?

                <div className="d-flex align-items-center mt-2">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <p className="ml-1">{data.email}</p>
                </div>
                :""}

                <div className="d-flex align-items-center mt-2">
                  <i class="fa fa-globe" aria-hidden="true"></i>
                  <p className="ml-1">{data.location}</p>{data.location ==""?"":","}<p className="ml-1">{data.city}</p> <p className="ml-1">{data.state}</p> <p className="mx-1">{data.zip_code}</p>
                </div>


                {jobfilterid.benefit&&jobfilterid.benefit.length>0?
                <div className="d-flex align-items-start flex-column mt-5 ">
                  <h4>Benefit</h4>
                  <ul style={{paddingLeft:"15px"}} className="mb-0">

                {jobfilterid.benefit&&jobfilterid.benefit.length>0?jobfilterid.benefit.map((data) =>
                  <li className="ml-1 whitle-left-dot">{parse(data.benefits)}</li>
                ):"No Benefits Found"}
                </ul>

                </div>
                :""}

{jobfilterid.responsibility&&jobfilterid.benefit.length>0?
                <div className="d-flex align-items-start flex-column mt-5 ">
                  <h4>Responsibility</h4>
                  <div className="next-ul-padding">
                {jobfilterid.responsibility&&jobfilterid.benefit.length>0?jobfilterid.responsibility.map((data) =>
                  <p className="ml-1 mb-0">{parse(data.responsibility)}</p>
                ):"No Responsibility Found"}
                </div>
                </div>
                :""}

                  {data.description!==undefined&&data.description!==null&&data.description!==""?
                <div className="d-flex align-items-start flex-column mt-5 ">
                  <h4>Description</h4>
                  <p className="ml-1">{parse(data.description)}</p>
                </div>:""
                  }

{data.company_overview!==undefined&&data.company_overview!==null&&data.company_overview!==""?
                <div className="d-flex align-items-start flex-column mt-5">
                  <h4>Company Overview</h4>
                  <div className="next-ul-padding">
                  <p className="ml-1">{parse(data.company_overview)}</p>
                  </div>
                </div>
:""
}

                {data.blog!==undefined&&data.blog!==null&&data.blog!==""?

                <div className="d-flex align-items-start flex-column mt-5">
                  <h4>Blog</h4>
                  <p className="ml-1">{data.blog}</p>
                </div>
:""
}

              {data.pay_rate_minimum!==null&&data.pay_rate_minimum!==undefined&&data.pay_rate_minimum!==""||data.pay_rate_maximum!==null&&data.pay_rate_maximum!==undefined&&data.pay_rate_maximum!==""?
                <div className="d-flex align-items-center mt-5">
                  <h4 className="mb-0">Pay Rate:</h4>
                  <h5 className="mb-0 ml-1 orange">{data.pay_rate_minimum} - {data.pay_rate_maximum} ({data.pay_rate_type})</h5>
                </div>:""
              }
                



                {data.other_compensations!==undefined&&data.other_compensations!==null&&data.other_compensations!==""?
                <div className="d-flex align-items-center mt-2">
                  <h4 className="mb-0">Other Compensations:</h4>
                  <h5 className="mb-0 ml-1 orange">{data.other_compensations}</h5>
                </div>
:""
}
{data.travel!==undefined&&data.travel!==null&&data.travel!==""?

                <div className="d-flex align-items-center mt-2">
                  <h4 className="mb-0">Travel:</h4>
                  <h5 className="mb-0 ml-1 orange">{data.travel}</h5>
                </div>
                :""
              }
            </div>
            ):""}
            	</>
}
        </div>

      </div>
    </>
    :<Loader/>}
    </>
  );
}

export default Jobs;


// code from scoopsolutions