import React, { useState,useEffect } from "react";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Logo from "../../assests/mob-logo.png";



function Pdf(props) {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      color: "black",
      width:"100%"
    },
    section2: {
      width:"100%",
      borderTop:"2px solid #dee2e6",
      borderBottom:"2px solid #dee2e6",
      paddingTop:10,
      display:"flex",
      paddingBottom:10,
      padding:20,
      marginTop:30,
    },
  });
  const [alluser,setalluser] = useState([])
  useEffect(() => {
    fetch('https://stag-server.hirechoices.com/getAllUser')
    .then(res => res.json())
    .then((response) => {
      setalluser(response.user)
    })
  }
  ,[]);

  
  useEffect(() => {
    fetch(`https://stag-server.hirechoices.com/All/invoice/${props.id}`)
    .then(res => res.json())
    .then(responce => setpdfdata(responce.invoices))
  }
  ,[]);
  const [pdfdata,setpdfdata] = useState([])

  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section2}>
        <img src={Logo}/>
        {pdfdata&&pdfdata.length>0?pdfdata.map((val,ind)=>
        <>
            <Text style={styles.text}>First Name: {alluser&&alluser.length>0?alluser[ind].first_name:""}</Text>
            <Text style={styles.text}>Last Name: {alluser&&alluser.length>0?alluser[ind].last_name:""}</Text>
            <Text style={styles.text}>Company Name: {alluser&&alluser.length>0?alluser[ind].company_name:""}</Text>
            <Text style={styles.text}>Payment Recieve: ${val.payment_recieve}</Text>
            <Text style={styles.text}>Invoice no: {val.invoice_no}</Text>
            <Text style={styles.text}>Status: {val.status}</Text>
            <Text style={styles.text}>Created at: {val.created_At}</Text>
            <Text style={styles.text}>Updated at: {val.updated_At}</Text>
        </>
        ):""}
      </View>
    </Page>
  </Document>  
    );
}

export default Pdf;


// code from scoopsolutions