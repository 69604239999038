import React, { useState, useEffect } from "react";
import "./resumes.css";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import { Tooltip, Button } from 'antd';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import CV from "../../assests/cv.png"
import dateFormat, { masks } from "dateformat";
import parse from 'html-react-parser';
import { Dropdown,Modal } from 'react-bootstrap';
import Loader from "../../components/fullpageloader/fullPageLoader"
import { Skeleton } from 'antd';





TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

function Resumes() {
  const [smloaders,setsmloaders]=useState(false)
  useEffect(() => {
    setsmloaders(true)
    fetch('https://stag-server.hirechoices.com/getAllResume')
        .then(res => res.json())
        .then((response) => {
          setgetresume(response.resume)
          console.log(response.resume,"asdsagoofi")
          jobid(response.resume[0].user_id)
        }
    )
    .then(response=>{
      setsmloaders(false)
    })
    .catch(()=>{
      setsmloaders(false)
    })
  }
    
  ,[]);



  const [getresume, setgetresume] = useState([]);


  
  const jobid = (val)=>{
    fetch(`https://stag-server.hirechoices.com/resume/getbyid/${val}`)
        .then(res => res.json())
        .then(response => setalljobsfilter(response)
    )
  }

  const [alljobsfilter, setalljobsfilter] = useState([]);
  console.log(alljobsfilter,"filter");
  const [deleteid,setdeleteid] = useState()


  const [loaders,setloaders]=useState(false)
  const [deleteshow, setdeleteshow] = useState(false);
  const delhandleClose = () => setdeleteshow(false);
  const delhandleShow = (val) => {
    setdeleteshow(true)
    setdeleteid(val)
    console.log(val);
  }
  const deleteresume = () =>{
    setloaders(true)
    const update = {id:deleteid};
    fetch("https://stag-server.hirechoices.com/resume/delete", {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify(update)
  })
  .then((res)=>{return res.json()}).then(response=>{
    window.location="/resumes"
    setTimeout(
      () => this.setState(setloaders(false)), 
      3000
    );
 })
 
}



const
keys = ['state'],
filtered = getresume.filter(
    (s => o => 
        (k => !s.has(k) && s.add(k))
        (keys.map(k => o[k]).join('|'))
    )
    (new Set)
);

const [filterState,setfilterState]=useState("")

  return (
    <>
    {!loaders ?
    <>
      <Modal show={deleteshow} onHide={delhandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are You Really Want To Delete This Resume
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{deleteresume()}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
      <div className="job-filtration-in_dash mt-0 d-flex">
              <select onChange={(e)=>{setfilterState(e.target.value)}} placeholder="Search by State">
              <option value="">Filter by State</option>
                {filtered&&filtered.length>0?filtered.map(val =>(
                  <>
                  <option value={val.state}>{val.state}</option>
                  </>
                  )):""}
                </select>
        </div>
      <div className="d-flex align-items-start mt-4">
        <div className="all-main-jobs-left">
        {smloaders?
            <>
            <Skeleton active/>
            <Skeleton active/>
            </>
            :
            <>
          {getresume&&getresume.length>0?getresume.filter((tar) => {
              if(filterState == ""){
                return(tar.state)
              }
              else{
                return(tar.state == filterState)
              }
            }).map(data =>(
          <>
          <div onClick={()=>{jobid(data.user_id)}} className="all-main-jobs-left-all-jobs">
              <div className="comapny-icon2">
                <img width="100%"  height="100%" src={CV} alt="" />
              </div>
              <div className="d-flex flex-column justify-content-start h-100 py-1">
                  <h5 className="mb-0">{data.first_name }</h5>
                  <p style={{borderBottom:"1px solid black",width:"fit-content"}}>{data.email}</p>
                  <p className="mt-0">{data.state}</p>
                  <p className="mt-2"><ReactTimeAgo date={data.created_at} locale="en-US"/></p>
                </div>
          </div>
          <hr/>
          </>
          )):
            <div className="w-100 bg-white text-center p-4">
              <h3 className="m-0">No Resume Are Available</h3>
            </div>
          }
          	</>
          }
        </div>

        

        {smloaders?
            <div className="all-main-jobs-right p-4 ">
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
            </div>
            :
            <>

        {alljobsfilter&&alljobsfilter.length>0?alljobsfilter.map(data =>(
        <div className="all-main-jobs-right p-4 ">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h4>{data.job[0].first_name}</h4><h4 className="ml-1">{data.job[0].last_name}</h4> 
              </div>
              <Dropdown>
                <Dropdown.Toggle className="drop-down-btn" id="dropdown-basic">
                <i class="fa fa-ellipsis-v text-dark" aria-hidden="true"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={()=>{delhandleShow(data.job[0].id)}}>
                    <i  class="fa fa-trash mr-2 text-danger" aria-hidden="true"></i>Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex align-items-center">
              <p>{data.job[0].city}</p> <span><h4 className="m-0">/</h4></span> <p><ReactTimeAgo date={data.job[0].created_at} locale="en-US"/></p>
            </div>

       

            {data.job[0].contact_number!==""&&data.job[0].contact_number!==undefined&&data.job[0].contact_number!==null?
            <div className="d-flex align-items-center mt-4">
              <i class="fa fa-mobile" aria-hidden="true"></i>
              <p className="ml-1">{data.job[0].contact_number}</p>
            </div>
            :""
            }



            {data.job[0].email!==""&&data.job[0].email!==undefined&&data.job[0].email!==null?
            <div className="d-flex align-items-center mt-2">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <p className="ml-1">{data.job[0].email}</p>
            </div>
            :""
            }

            <div className="d-flex align-items-center mt-2">
              <i class="fa fa-globe" aria-hidden="true"></i>
              <p className="mx-1">{data.job[0].city}</p><p className="mx-1">{data.job[0].state}</p><p className="mx-1">{data.job[0].zipcode}</p>
            </div>





            <div className="d-flex align-items-start flex-column mt-5 ">
              <h4>Employment Eligibility</h4>
              <p className="ml-1">{data.job[0].employment_eligibility}</p>
            </div>



            {data.job[0].professional_summary!==""&&data.job[0].professional_summary!==undefined&&data.job[0].professional_summary!==null?
            <div className="d-flex align-items-start flex-column mt-5">
              <h4>Professional Summary</h4>
              <p className="ml-1">{parse(data.job[0].professional_summary)}</p>
            </div>
            :""
            }

            {data.award.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Awards</h4>
              {data.award.length>0?data.award.map(award=>(
                <div className="pay-range-not mt-2">
                  <h4 className="m-0" style={{color:"#ec8a23"}}>{award.title}</h4>
                  <p>{award.date}</p>
                </div>
              )):""
              }
            </div>
            :""
            }

{data.education.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Education</h4>
              {data.education.map(education=>(
                <div className="pay-range-not mt-2">

                  
                  {education.level_of_education!==""&&education.level_of_education!==undefined&&education.level_of_education!==null?
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Level Of Education:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{education.level_of_education}</h6>
                  </div>
                  :""
                  }



                  {education.school!==""&&education.school!==undefined&&education.school!==null?
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">School:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{education.school}</h6>
                  </div>
                  :""
                  }


                  {education.degree!==""&&education.degree!==undefined&&education.degree!==null?
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Degree:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{education.degree}</h6>
                  </div>
                    :""
                    }


{education.major!==""&&education.major!==undefined&&education.major!==null?
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Major:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{education.major}</h6>
                  </div>

:""
                    }

{education.city!==""&&education.city!==undefined&&education.city!==null?
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">City:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{education.city}</h6>
                  </div>
:""
}


                    {education.date_of_completion!==""&&education.date_of_completion!==undefined&&education.date_of_completion!==null?
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Date Of Completion:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{education.date_of_completion}</h6>
                  </div>
:""
}



                </div>
              ))
              }
            </div>:""
}

{data.experience.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Experience</h4>
              {data.experience.map(experience=>{

                const tmdatest = experience.time_period_start.split("-")[0]+"-"+experience.time_period_start.split("-")[1]
                const enddate_exp = experience.time_period_end.split("-")[0]+"-"+experience.time_period_end.split("-")[1]

                return(
                <div className="pay-range-not mt-2">
                <div className="d-flex align-items-center">
                  <h5 className="m-0">Job Title:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{experience.job_title}</h6>
                </div>



                <div className="d-flex align-items-center">
                  <h5 className="m-0">Company:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{experience.company}</h6>
                </div>




                <div className="d-flex align-items-center">
                  <h5 className="m-0">City:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{experience.city}</h6>
                </div>





                <div className="d-flex align-items-center">
                  <h5 className="m-0">State:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{experience.state}</h6>
                </div>






                <div className="d-flex align-items-center">
                  <h5 className="m-0">Time Period Start:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{tmdatest}</h6>
                </div>





                <div className="d-flex align-items-center">
                  <h5 className="m-0">Time Period End:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{experience.time_period_end == "Still currently employed here"?experience.time_period_end:enddate_exp}</h6>
                </div>



                <div className="d-flex align-items-center">
                  <h5 className="m-0">Duties:</h5>
                  
                </div>
                <div className="next-ul-padding">
                {parse(experience.duties)}
                </div>


                </div>
                )
                })}
            </div>
:""
}
{data.certification.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Certification</h4>
              {data.certification.map(certification=>(
                <div className="pay-range-not mt-2">
                  <h4 className="m-0" style={{color:"#ec8a23"}}>{certification.title}</h4>
                  <p>{certification.date}</p>
                </div>
              ))
              }
            </div>
:""}


{data.language.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Language</h4>
              {data.language.map(language=>(
                <div className="pay-range-not mt-2">
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Language Name:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{language.lang}</h6>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Level:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{language.level}</h6>
                  </div>
                </div>
              ))
              }
            </div>
:""}


{data.military.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Military</h4>
              {data.military.map(military=>(
               <div className="pay-range-not mt-2">
                <div className="d-flex align-items-center">
                  <h5 className="m-0">Military Service:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{military.military_service}</h6>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="m-0">Country:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{military.country}</h6>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="m-0">Rank:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{military.rank}</h6>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="m-0">Start Date:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{military.start_date}</h6>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="m-0">End Date:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{military.end_date}</h6>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="m-0">Security Clearance:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{military.security_clearance}</h6>
                </div>
              </div>
                ))
                }
            </div>
            :""}


{data.mos.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Military mos</h4>
              {data.mos.map(mos=>(
                <div className="pay-range-not mt-2">
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">mos:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{mos.mos}</h6>
                  </div>
                </div>
              ))
              }
            </div>
  :""}

{data.branch.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Military Branch</h4>
              {data.branch.map(branch=>(
                <div className="pay-range-not mt-2">
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Branch:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{branch.branch}</h6>
                  </div>
                </div>
              ))
              }
            </div>
 :""}

{data.reference.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Reference</h4>
              {data.reference.map(reference=>(
                <div className="pay-range-not mt-2">
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Name:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{reference.name}</h6>
                  </div>



                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Contact Number:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{reference.contact_number}</h6>
                  </div>


                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Relationship:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{reference.relationship}</h6>
                  </div>

                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Email:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{reference.email}</h6>
                  </div>
                </div>
              ))
              }
            </div>
:""}
{data.volunteer.length>0?
            <div className="d-flex align-items-start flex-column mt-5">
            <h4 className="mb-0">Volunteer</h4>
              {data.volunteer.map(volunteer=>(
                <div className="pay-range-not mt-2">
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Title:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{volunteer.title}</h6>
                  </div>
                  <div className="d-flex align-items-center">
                    <h5 className="m-0">Start Date:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{volunteer.start}</h6>
                  </div>

                  <div className="d-flex align-items-center">
                    <h5 className="m-0">End Date:</h5><h6 className="mb-0" style={{color:"#ec8a23",marginLeft:"3px"}}>{volunteer.end}</h6>
                  </div>
                </div>
              ))
              }
            </div>
:""}

        </div>

        )):""}

</>
}

      </div>

    </div>
    </>
    :<Loader/>}
    </>
  );
}

export default Resumes;

// code from scoopsolutions