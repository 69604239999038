import React, { useState, useEffect } from "react";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import "../Dashboard/Dashboard.css";

function Dashboard() {
  const[totaluser,settotaluser] = useState([])
  const[totalresume,settotalresume] = useState([])
  const[totaljobs,settotaljobs] = useState([])
  useEffect(()=>{
    fetch("https://stag-server.hirechoices.com/getAllUser")
    .then(res => res.json())
    .then(response => settotaluser(response.user))

    fetch("https://stag-server.hirechoices.com/getAllResume")
    .then(res => res.json())
    .then(response => settotalresume(response.resume))

    fetch("https://stag-server.hirechoices.com/getAlljobs")
    .then(res => res.json())
    .then(response => settotaljobs(response.resume))
  },[])
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="dashboard-parent all-main responsive-tal">
        <div className="dashboard-child-02 mt-0">
          <section className="jobs-posted-heading">
            <div className="jobs-posted-heading-text">
                <h3>Total User</h3>
                <h1>{totaluser.length}</h1>
            </div>
            <div className="jobs-posted-heading-icon">
              <i class="fa fa-users" aria-hidden="true"></i>
            </div>
          </section>


          <section className="jobs-posted-heading ml-2">
            <div className="jobs-posted-heading-text">
                <h3>Total Resumes</h3>
                <h1>{totalresume.length}</h1>
            </div>
            <div className="jobs-posted-heading-icon">
              <i class="fa fa-file" aria-hidden="true"></i>
            </div>
          </section>



          <section className="jobs-posted-heading ml-2">
            <div className="jobs-posted-heading-text">
                <h3>Total Jobs</h3>
                <h1>{totaljobs.length}</h1>
            </div>
            <div className="jobs-posted-heading-icon">
            <i class="fa fa-briefcase" aria-hidden="true"></i>
            </div>
          </section>


        </div>
      </div>
    </>
  );
}
export default Dashboard;

// code from scoopsolutions
